<template>
  <div class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline is-centered">
          <div class="column is-5-desktop is-8-tablet">
            <div class="card px-6 py-4">
              <div class="card-content">
                <div class="block has-text-centered">
                  <img class="logo_img" src="@/assets/logo_barta.png" alt="Barta">
                </div>
                <template v-if="hasErrorUrlToken">
                  <div class="block">
                    <h1 class="font_20 has_gray has-text-weight-bold has-text-centered mb-5">
                      Url Invalida
                    </h1>
                    <p class="font_14 has_gray has-text-centered">
                      Asegúrese de acceder mediante la url que se le ha enviado a su correo.
                    </p>
                  </div>
                </template>
                <template v-else>
                  <div class="block mb-6">
                    <h1 class="font_20 has_gray has-text-weight-bold has-text-centered">
                      Recuperar Contraseña
                    </h1>
                  </div>
                  <form @submit.prevent="recoveryPassword" class="columns is-multiline">
                    <div class="column is-12">
                      <div class="field control">
                        <label class="label has_gray" for="password">
                          Contraseña
                        </label>
                        <input
                          id="password"
                          ref="password"
                          type="password"
                          class="input"
                          name="password"
                          placeholder="********"
                          v-model="password"
                          v-validate="'required'"
                          data-vv-as="de la contraseña"
                          autocomplete="on"
                        >
                        <span v-show="vverrors.has('password')" class="help is-danger">{{ vverrors.first('password') }}</span>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field control">
                        <label class="label has_gray" for="passwordConfirm">
                          Contraseña
                        </label>
                        <input
                          id="passwordConfirm"
                          class="input"
                          type="password"
                          name="passwordConfirm"
                          placeholder="*******"
                          v-validate="'required|confirmed:password'"
                          data-vv-as="de confirmar contraseña"
                          autocomplete="confirm-new-password"
                        >
                        <span v-show="vverrors.has('passwordConfirm')" class="help is-danger">{{ vverrors.first('passwordConfirm') }}</span>
                      </div>
                    </div>
                    <div class="column is-12 mb-2 mt-5">
                    <div class="block has-text-centered block_btn">
                      <button
                        type="submit"
                        class="button btn_violet"
                        :class="{ 'is-loading' : loading }"
                      >
                        Recuperar contraseña
                      </button>
                    </div>
                  </div>
                  </form>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'RecoveryPasswordForm',
  data () {
    return {
      loading: false,
      password: null,
      urlToken: this.$route.query.token,
      hasErrorUrlToken: true
    }
  },
  methods: {
    ...mapActions(['passwordRecovery']),
    async recoveryPassword () {
      if (await this.$validator.validateAll()) {
        if (this.urlToken) {
          this.loading = true
          const data = {
            token: this.urlToken,
            password: this.password
          }
          const resp = await this.passwordRecovery(data)
          if (resp) {
            this.password = null
            this.$router.push({ name: 'login' })
          }
          this.loading = false
        }
      }
    }
  },
  beforeMount () {
    if (this.urlToken) {
      this.hasErrorUrlToken = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .hero {
    background-color: #f7f7fa;
  }
  .card {
    border-radius: 8px;
    box-shadow: 0 2px 55px 0 rgba(188, 188, 222, 0.27);
    background-color: #fdfdfd;
  }
  .logo_img {
    width: 115px;
  }
  @media screen and (max-width: 768px) {
  }
</style>
